import { fake, request, i18next } from 'tools'

export const getPublicCard = id => request.get( `cards/${id}/public`, { lang: ( i18next.language || 'en' ).split( /[-_]+/ )[ 0 ].toLowerCase() })
export const verifyDomain = domain => request.get( 'corporate/domains', { domain })
export const registerDevice = data => request.post( 'auth/register/device', data, { headers: {} })
export const basicRegistration = data => request.patch( 'users/lite', data )
export const updateAvatar = data => request.patch( 'users', data )
export const updateClaimCorp = data => request.patch( 'users', data )
export const getAddedMail = id => request.get( 'contacts', id )
export const finalRegistration = data => request.patch( 'users/completion', data )
export const activateUser = ({ id, ...body }) => request.patch( `cards/${id}/activate`, body )
export const migrateCard = ({ id, ...body }) => request.post( `cards/${id}/migrate`, body )
export const updateCard = ({ id, ...body }) => request.patch( `cards/${id}`, body )
export const exchangePublicContacts = data => request.post( 'links/public', data )
export const exchangeContacts = data => request.post( 'links', data )
export const checkDuplicate = ({ owner_card, recipient }) => request.get( `links/duplicate_check?owner=${owner_card}&recipient=${recipient}&format=json` )
export const exchangeValidate = ({ link, ...data }) => request.post( `links/validate_recipient/${link}?format=json`, data )
export const updateContact = ({ id, ...data }) => request.patch( `contacts/${id}`, data )
export const searchCard = email => request.get( 'cards/find_card_public', { email })
export const searchCardUID = uid => request.get( 'cards/find_card_public', { uid })
export const getProjectConfig = id => request.get( `config?project=${id}`, null, null, { headers: {} })

export const oneRegForAll = body => request.post( 'v2/register', body, { headers: {} })

export const addContact = data => {
    if ( !data.contact ) { return fake.resolveAfter({ data: null }, 100, data ) }
    return request.post( 'contacts', data )
}

export const removeContact = id => request.remove( `contacts/${id}` )

export const getCustomDesigns = () => request.get( 'corporate/companies/search?is_design=true' )
