import { Component }                 from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Adjust                        from '@adjustcom/adjust-web-sdk'
import { LinkedInCallback }          from 'react-linkedin-login-oauth2'
import TabeeAuth                     from '@tabee/frontend-auth'

import { PublicCard, DigitalWallet, CardByCode, AuthCheck, AddContact, VerifyLink } from 'pages'
import { users, request, i18next, generate }                                        from 'tools'
import config                                                                       from 'config'

import connector from './appConnector'
import './app.scss'
import './styles/index.scss'

class App extends Component {

    constructor ( props ) {
        super( props )

        this.state = {
            accountChecked:     false,
            accountCheckHash:   generate.uniq(),
            latestAccountCheck: 0
        }

        this.set = generate.set( this )

        TabeeAuth.setApi( config.api.url )
        TabeeAuth.setSSO( config.sso.url )
        TabeeAuth.setProject( config.api.project )
    }

    componentDidMount () {
        this.checkForcedReAuth()

        const
            id = request.storage.get( 'user_id' )

        ;( id ) && ( this.props.getSelfUser())

        i18next.on( 'languageChanged', () => this.forceUpdate())

        window.addEventListener( 'message', event => {
            if ( event.data && event.data !== 'undefined' && typeof event.data === 'string' ) {
                const
                    data = JSON.parse( event.data )

                if ( data.sameUser === false && ( !!id || !!data.userId )) {
                    this.set.accountChecked( false )
                    console.error( event.data )
                    //TabeeAuth.SSOSignIn( `${window.location.host}/auth/sso?return_url=${encodeURIComponent( window.location.pathname )}` )
                }
            }
        })

        window.addEventListener( 'focus', () => {
            this.accountCheck()
        })
    }

    componentDidUpdate ( prevProps, prevState ) {
        const
            { user, current, account, companies } = this.props,
            { accountCheckHash, latestAccountCheck } = this.state

        if ( user !== prevProps.user && !!user ) {
            Adjust.addGlobalCallbackParameters([
                { key: 'userID', value: user.id },
                { key: 'language_local', value: i18next.language },
                { key: 'language', value: window.navigator.language },
                { key: 'email', value: user.email }
            ])

            ;( !current && account === user.id ) && ( this.props.setAccount( account ))
            ;( !!account && account !== 'undefined' ) && ( users.load( this.props ))
        }

        (
            ( companies !== prevProps.companies || user !== prevProps.user ) &&
            companies && user &&
            !current && !!account && account !== 'undefined' &&
            account !== user.id
        ) && (
            this.props.setAccount( account )
        )

        if ( accountCheckHash === prevState.accountCheckHash && Date.now() - latestAccountCheck >= 10000 ) {
            this.accountCheck()
        }
    }

    accountCheck = () => {
        this.set.accountCheckHash( generate.uniq())
        this.set.latestAccountCheck( Date.now())
    }

    checkForcedReAuth = () => {
        const
            params = new URLSearchParams( window.location.search ),
            token = params.get( 'hash' ),
            user = params.get( 'forced' )

        if ( user ) {
            this.props.setAuthData({
                status:  true,
                token:   token,
                account: user
            })

            params.delete( 'hash' )
            params.delete( 'forced' )
            history.replaceState({}, null, `${window.location.pathname}${ Array.from( params.keys()).length > 0 ? `?${ params.toString() }` : ''}` )
        }
    }

    render () {
        const
            { account } = this.props,
            { accountCheckHash } = this.state,
            defaultRedirect = () => {
                window.location.href = config.indexPage
                return null
            }

        return (
            <div className="app-page">
                <Switch>
                    <Route exact path="/" component={defaultRedirect} />
                    <Route exact path="/u" component={defaultRedirect} />
                    <Route exact path="/p" component={defaultRedirect} />
                    <Route exact path="/c" component={defaultRedirect} />

                    <Route extact path="/c/:id">
                        <CardByCode />
                    </Route>

                    <Route extact path="/v/:id">
                        <VerifyLink />
                    </Route>

                    <Route exact path="/u/:id">
                        <PublicCard />
                    </Route>
                    <Route exact path="/u/:id/*">
                        <PublicCard />
                    </Route>

                    <Route exact path="/p/:id">
                        <PublicCard />
                    </Route>
                    <Route exact path="/p/:id/*">
                        <PublicCard />
                    </Route>

                    <Route exact path="/found/:id">
                        <PublicCard />
                    </Route>
                    <Route exact path="/found/:id/*">
                        <PublicCard />
                    </Route>

                    <Route exact path="/digital">
                        <DigitalWallet />
                    </Route>
                    <Route exact path="/digital/:id">
                        <PublicCard />
                    </Route>
                    <Route exact path="/digital/:id/*">
                        <PublicCard />
                    </Route>

                    <Route path="/auth/:mode">
                        <AuthCheck />
                    </Route>

                    <Route exact path="/add">
                        <AddContact />
                    </Route>
                    <Route exact path="/linkedin" component={LinkedInCallback} />
                </Switch>

                <iframe
                    src = { `${config.sso.url}/same-user/${account}?${accountCheckHash}` }
                    style = {{ position: 'absolute', left: -9999, top: -9999, opacity: 0, width: 1, height: 1 }}

                    onLoad = {() => this.set.accountChecked( true )}
                />
            </div>
        )
    }
}

export default connector( withRouter( App ))
