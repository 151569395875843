/* IMPORT */
import { connect }                    from 'react-redux'
import { publicActions, userActions } from 'services'

/* EXPORT */

const mapStateToProps = state => ({
    account:        state.user.account,
    justCreated:    state.user.justCreated,
    user:           state.user.info,
    error:          state.public.error,
    data:           state.public.card,
    verify:         state.public.domain,
    steps:          state.public.steps,
    search:         state.public.search,
    active:         state.public.activate,
    activated:      state.public.activated,
    userCards:      state.user.cards,
    shared:         state.public.shared,
    migrated:       state.public.migrate,
    types:          state.public.types,
    socials:        state.public.socials,
    onboarding:     state.public.onboarding,
    needSave:       state.public.needSave,
    precreated:     state.public.precreated,
    contacts:       state.user.contacts,
    reloadContacts: state.public.reloadContacts,
    customDesigns:  state.public.customDesigns,
    cardSaved:      state.public.cardSaved,
    userInfo:       state.auth.info,
    currentCard:    state.public.currentUserCard,
    validateLink:   state.public.validateLink,
    linkExist:      state.public.linkExist
}),

      allActions = {
          ...userActions,
          ...publicActions
      }

export default connect( mapStateToProps, allActions )
