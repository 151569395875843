import ReactPixel  from 'react-facebook-pixel'
import ReactGA     from 'react-ga4'
import LinkedInTag from 'react-linkedin-insight'

class Analytics {

    events = {
        addPaymentInfo:       'AddPaymentInfo',
        addToCart:            'AddToCart',
        addToWishlist:        'AddToWishlist',
        completeRegistration: 'CompleteRegistration',
        contact:              'Contact',
        customizeProduct:     'CustomizeProduct',
        donate:               'Donate',
        findLocation:         'FindLocation',
        initiateCheckout:     'InitiateCheckout',
        lead:                 'Lead',
        purchaseSuccess:      'Purchase',
        schedule:             'Schedule',
        search:               'Search',
        submitApplication:    'SubmitApplication',
        trialSuccess:         'StartTrial',
        trialCancel:          'CancelledTrial',
        viewContent:          'ViewContent',
    }

    liMapper = {
        CompleteRegistration: 12525866,
        InitiateCheckout:     12525874,
        Contact:              12525890,
        StartTrial:           12525882,
    }

    init () {
        ReactPixel.init( '775975303882457', { debug: true })
        ReactGA.initialize( 'G-Z23VGB9MC3' )
        LinkedInTag.init( '4948410' )
    }

    trackLIEvent ( event ) {
        const
            cid = this.liMapper[ event ]

        if ( !cid ) {
            return
        }

        LinkedInTag.track( cid )
    }

    track ( event, rawData ) {
        if ( !Object.values( this.events ).includes( event )) {
            throw new Error( `Unknown event: ${event}` )
        }

        const
            fbData = {},
            gaData = { action: event }

        if ( rawData ) {
            if ( rawData.name ) {
                fbData.content_name = rawData.name
                gaData.label = rawData.name
            }

            if ( rawData.value || rawData === 0 ) {
                fbData.value = rawData.value
                gaData.value = rawData.value
            }

            if ( rawData.currency ) {
                fbData.currency = rawData.currency
                gaData.label ? gaData.label += rawData.currency : gaData.label = rawData.currency
            }

            if ( rawData.predicted_ltv ) {
                fbData.predicted_ltv = rawData.predicted_ltv
            }
        }

        ReactPixel.track( event, fbData )
        ReactGA.event( event, gaData )
        this.trackLIEvent( event )
    }

}

export default new Analytics()
