/* Styles (!) Important to be at the top */
import 'antd/dist/antd.css'

/* REACT */

import ReactDOM from 'react-dom'

/* REDUX */
import { Provider } from 'react-redux'
import storage      from 'storage'

/* TOOLS */
import { BrowserRouter }  from 'react-router-dom'
import * as serviceWorker from './serviceWorker'

import moment from 'moment'
import axios  from 'axios'

/* ANALYTICS */

/* SENTRY */
import * as Sentry      from '@sentry/react'
import { Integrations } from '@sentry/tracing'

/* Amplitude */
import amplitude             from 'amplitude-js'
import { AmplitudeProvider } from '@amplitude/react-amplitude'

/* Adjust */
import Adjust from '@adjustcom/adjust-web-sdk'

/* Pushwoosh */
//import {Pushwoosh} from 'web-push-notifications'

/* /ANALYTICS */

/* Components */
import App       from './App'
import analytics from 'tools/analytics'

const
    SENTRY_DSN = 'https://293fdc5e362348c594ce5273d4777eba@o57867.ingest.sentry.io/4505183995428864',
    AMPLITUDE_KEY = 'd27ec9f5a740534b5b3aea97a56bb6e0',
    ADJUST_APPTOKEN = 'gb2fd9oiofeo'

setTimeout(() => {
    /*
    const
        pwInstance = new Pushwoosh()
    */

    analytics.init()

    if ( ![ '3000', '3001', '3002' ].includes( window.location.port )) {

        /* SENTRY */
        Sentry.init({
            dsn:          SENTRY_DSN,
            integrations: [
                new Integrations.BrowserTracing(),
            ],

            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: 1.0,
        })

        /*
        pwInstance.push(['init', {
            logLevel: 'info',
            applicationCode: 'E654A-E834E',
            safariWebsitePushID: 'web.tabee.app',
            defaultNotificationTitle: 'Tabee',
            defaultNotificationImage: 'https://tabee.app/wp-content/themes/tabee/assets/images/favicon/touch-114.png',
            autoSubscribe: false,
            subscribeWidget: {
                // Integration doc is here http://tabee.mobi/pw-widget
                enable: false,
                position: 'bottomLeft', //possible values: ‘bottomLeft’, ‘bottomRight’, ‘topLeft’, ‘topRight’
                bgColor: '#1A2433',
                bellColor: 'white',
                bellStrokeColor: '#1A2433',
                bellButtonBorder: '1px solid #1A2433',
                shadow: '0px 0px 6px rgba(0, 0, 0, 0.75)',
                size: '30px',
                indent: '20px',
                zIndex: '999999',
                tooltipText: {
                    successSubscribe: 'You are successfully subscribed!s',
                    needSubscribe: 'Get notifications about important updates',
                    blockSubscribe: 'Click to see how to get notifications',
                    alreadySubscribed: 'You are already subscribed'
                }
            },
            userId: localStorage.getItem( '_tabee_user_id' ) || '',
            tags: {
                // 'Language': 'ЗНАЧЕНИЕ_ИЗ_БРАУЗЕРА',
                // 'Name': 'ИМЯ_ИЗ_ЮЗЕРА',
                // 'Surname': 'ФАМИЛИЯ_ИЗ_ЮЗЕРА',
                // 'user_last_login': 'UNIX_TIME_CURRENT_SESSION_START',
                'project': '541ff58e-7d11-11eb-8031-6a13bef2453b'
            }
        }])

        pwInstance.push(function(api) {
            console.log('Push is ready')
        })
        */
    }

    const
        origLog = console.log,
        origError = console.error

    console.log = function log () {
        if ( arguments.length > 0 && arguments[ 0 ] === '[adjust-sdk]' ) {
            let
                found = null,
                i = 0

            for ( i = 0; i < arguments.length; i++ ) {
                if ( typeof arguments[ i ] === 'string' && arguments[ i ].indexOf( 'web_uuid' ) > -1 ) {
                    found = arguments[ i ]
                    break
                }
            }

            if ( found ) {
                window._adjust_web_uuid = found.match( /web_uuid set to ([a-z0-9\\-]+)/i )?.[ 1 ]
            }
        } else {
            origLog( ...arguments )
        }
    }

    console.error = function err () {
        //visibleLog( ...arguments )
        origError( ...arguments )
    }

    function visibleLog () {
        if ( process.env.NODE_ENV === 'production' ) { return }

        const
            $container = getVisibleLogContainer()

        let
            $div

        for ( let i = 0; i < arguments.length; i++ ) {
            $div = document.createElement( 'div' )

            $div.style.marginBottom = '10px'
            $div.style.backgroundColor = 'red'
            $div.style.color = '#fff'

            $div.innerText = arguments[ 0 ].toString()

            $container.appendChild( $div )
        }
    }

    function getVisibleLogContainer () {
        const
            $container = document.querySelector( '.console-error-visible' )

        if ( $container ) {
            return $container
        }

        const
            $div = document.createElement( 'div' )

        $div.style.position = 'absolute'
        $div.style.top = 0
        $div.style.right = 0
        $div.style.height = '100vh'
        $div.style.overflow = 'auto'
        $div.style.pointerEvents = 'none'

        $div.className = 'console-error-visible'

        document.body.appendChild( $div )

        return $div
    }

    /* Adjust */
    Adjust.initSdk({
        appToken:    ADJUST_APPTOKEN,
        environment: process.env.NODE_ENV === 'production' ? 'production' : 'sandbox',
        logLevel:    'verbose'
    })

    Adjust.addGlobalCallbackParameters([
    //    {key: 'userID', value: 'USER_UUID'},
    //    {key: 'email', value: 'EMAIL_FROM_USER'},
        { key: 'projectId', value: '541ff58e-7d11-11eb-8031-6a13bef2453b' }
    ])
}, 100 )

window.moment = moment
window.axios = axios

ReactDOM.render(
    <AmplitudeProvider
        amplitudeInstance = { amplitude.getInstance() }
        apiKey = { AMPLITUDE_KEY }
    >
        <Provider store={storage}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </AmplitudeProvider>,
    document.getElementById( 'root' )
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
